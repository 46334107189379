'use client'
import Script from 'next/script'

const GoogleAnalytics = () => {
  const google = process.env.NEXT_PUBLIC_MEASUREMENT_ID || ''
  const production = process.env.NEXT_PUBLIC_ENV === 'production' ? true : false || false
  return (
    <>
      {production && (
        <div>
          {/* Google Analytics */}
          <Script
            id="ga-script-src"
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=G-${google}`}
          />
          <Script
            id="ga-script-config"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-${google}');
              `
            }}
          />
        </div>
      )}
    </>
  )
}

export default GoogleAnalytics
