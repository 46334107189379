import Image from 'next/image'
import logo from './Logo-new.svg'
import classNames from 'classnames'

export default function LogoIcon({ classname }: { classname?: string }) {
  return (
    <div className="w-full h-full flex items-center justify-center pl-6">
      <Image
        src={logo.src}
        alt="logo-slip2go"
        className={classNames('w-auto h-auto', classname)}
        width={133}
        height={79}
        quality={100}
        fetchPriority="high"
        priority={true}
        placeholder="empty"
        sizes={`(max-width: 768px) 80px, 110px`}
      />
    </div>
  )
}
