import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/react-quill/dist/quill.snow.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/clientErrorHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/components/layout.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/icons/Logo-new.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/icons/registerIcon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/layout/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/layout/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/googleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","GoogleTagManagerNoScript"] */ "/home/runner/work/slip-website/slip-website/src/googleTagManage.tsx");
