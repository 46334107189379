'use client'

import Script from 'next/script'

interface IProps {
  gtmId: string
}

const GoogleTagManagerImport = (props: IProps) => {
  return (
    <>
      {/* Google Tag Manager - Script */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${props.gtmId}');
          `}
      </Script>
    </>
  )
}

export const GoogleTagManagerNoScript = (props: IProps) => {
  return (
    // Google Tag Manager - NoScript - แก้ไขโดยลบ noscript ที่ซ้ำซ้อน
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${props.gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  )
}

export default GoogleTagManagerImport
