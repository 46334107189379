'use client'

import Image from 'next/image'
import logo from '@/app/icons/logoSliptogo.svg'
import hamburger from '@/app/icons/hamburger.svg'
import RegisterIcon from '@/app/icons/registerLogo'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Close from '@/app/icons/close.svg'
import { usePathname } from 'next/navigation'
import Link from 'next/link'

export default function PageNavMobile({ locale }: { locale: string }) {
  const [openMenuMobile, setOpenMenuMobile] = useState(false)
  useEffect(() => {
    if (process.browser) {
      setTimeout(() => {
        if (openMenuMobile) {
          document.body.classList.add('modalOpenUp')
        } else {
          document.body.classList.remove('modalOpenUp')
        }
      }, 100)
    }
  }, [openMenuMobile])
  const navi = usePathname()
  useEffect(() => {
    setOpenMenuMobile(false)
  }, [navi])
  const menu = [
    {
      title: 'หน้าแรก',
      path: `/`
    },
    {
      title: 'บริการของเรา',
      path: `/service`
    },
    {
      title: 'ราคาและแพ็กเกจ',
      path: `/pricing`
    },
    {
      title: 'ข่าวสาร',
      path: `/blog`
    },
    {
      title: 'คำถามที่พบบ่อย',
      path: `/faq`
    },
    {
      title: 'คู่มือการใช้งาน',
      path: `/guide`
    },
    {
      title: 'ติดต่อเรา',
      path: `/about`
    }
  ]
  const isPathMatching = (currentPath: string, menuPath: string) => {
    if (menuPath === '/') {
      return currentPath === '/'
    }
    if (
      currentPath === '/service/line-oa' ||
      currentPath === '/service/line-group' ||
      currentPath === '/service/line-1on1' ||
      currentPath === '/service/api-connect'
    ) {
      return '/service' === menuPath
    }
    if (menuPath === '/blog') {
      if (currentPath === '/blog') {
        return true
      }
      if (currentPath.startsWith('/blog/')) {
        return true
      }

      return false
    }

    return currentPath === menuPath
  }
  return (
    <div className="bg-white">
      <div className="w-full h-10">
        <div className="w-full h-full flex justify-between items-center border-primary-ocean-blue-700 border border-t-0 border-r-0 border-l-0 border-b border-opacity-5">
          <div className="pl-[18px] flex items-center">
            <Link href="/">
              <Image
                className="w-[70px] h-[22px]"
                src={logo}
                alt="logoMobile"
                width={70}
                height={22}
                quality={100}
                loading="lazy"
                blurDataURL="data:image/jpeg"
              />
            </Link>
          </div>
          <div className="flex-none pr-[18px] flex items-center px-3 py-1">
            <Link href={process.env.NEXT_PUBLIC_LOGIN || '#'}>
              <div className="flex items-center bg-primary-ocean-blue-base rounded-full w-fit p-1 justify-center">
                <RegisterIcon />
                <h5 className="text-white-true pl-1 font-sukhumvit font-sukhumvitw700 text-sukhumvit14">
                  สมัครใช้งาน/เข้าสู่ระบบ
                </h5>
              </div>
            </Link>
            <div className="pl-4">
              <Image
                onClick={(): void => {
                  setOpenMenuMobile(!openMenuMobile)
                }}
                className="w-6 h-6"
                src={hamburger}
                alt="hamburger"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'bg-white fixed z-50 overflow-hidden w-full transition-all',
          openMenuMobile
            ? 'h-screen opacity-100 top-0 duration-700'
            : 'h-0 opacity-0 -top-full duration-700'
        )}
      >
        <div className="h-10 px-[18px] flex items-center justify-between">
          <div
            onClick={(): void => {
              setOpenMenuMobile(!openMenuMobile)
            }}
          >
            <Image alt="closeMenuMobile" src={Close} />
          </div>
        </div>
        <div className="flex flex-col justify-between h-[calc(100vh-40px)]">
          <div className="px-[18px] pt-4">
            <div className="w-[calc(100%-120px)] flex flex-col">
              {menu.map((m: any, index: number) => {
                return (
                  <Link
                    href={`${m.path}`}
                    key={index}
                    locale={locale}
                    onClick={(): void => {
                      setOpenMenuMobile(false)
                    }}
                    className={classNames(
                      'min-w-fit max-w-min pb-3 text-sukhumvit16 font-sukhumvit font-sukhumvitw700',
                      'min-w-fit max-w-min text-sukhumvit16 font-sukhumvit font-sukhumvitw700',
                      isPathMatching(navi, m.path)
                        ? 'text-primary-ocean-blue-600'
                        : 'text-neutral-gray-700 hover:text-primary-ocean-blue-600 hover:cursor-pointer'
                    )}
                  >
                    <h5>{m.title}</h5>
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="pb-4 px-[18px]">
            <Link href={process.env.NEXT_PUBLIC_LOGIN || '#'}>
              <div className="flex w-full items-center bg-primary-ocean-blue-base rounded-full h-[36px] justify-center">
                <RegisterIcon />
                <h5 className="text-white-true pl-1 font-sukhumvit font-sukhumvitw700 text-sukhumvit16">
                  สมัครใช้งาน/เข้าสู่ระบบ
                </h5>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
